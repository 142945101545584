<template>
  <div class="persalePopup">
    <van-overlay :show="isOpen" z-index="20" @click="close">
      <!-- <div class="wrapper" @click.stop>
        <div class="btn flex-center-center" @click="jumpPage('/presale')">
          ¥{{ amount }} 前往支付尾款
        </div>
      </div> -->
      <div @click.stop="jumpPage('/presale')">
        <ImgDecypt
          class="wrapper"
          :src="presalePopBg"
          :key="presalePopBg"
          v-if="presalePopBg"
        />
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { judgeTime } from "@/utils/index";
import { getPresaleList } from "@/api/home";
import ImgDecypt from "@/components/ImgDecypt";
export default {
  name: "PersalePopup",
  components: {
    ImgDecypt,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    presalePopBg: {
      type: String,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },
  // computed: {
  //   ...mapGetters({
  //     preInfo: "preInfo",
  //   }),
  // },
  // watch: {
  //     advList() {

  //     }
  // },
  data() {
    return {
      isShow: true,
      bgImg: "",
      // advInfo: {},
    };
  },
  created() {
    // this.getPresaleData();
    // this.advList = getAdItem(AdType.FREE_VIDEO).reverse();
    // console.log(this.advList)
    // if(advList.length > 0 ){
    //     this.advList = advList
    //     console.log(this.advList)
    //     // if (getSessionItem('advPicturePop') != '1') {
    //     //     setSessionItem('advPicturePop', '1')
    //     //     this.advPictureShow = true;
    //     // }
    // }
  },
  methods: {
    jumpPage(path) {
      if (path) {
        this.$router.push(path);
        this.$emit("close");
      }
    },
    close() {
      this.$emit("close");
    },

    // async getPresaleData() {
    //   let req = {
    //     pageNumber: 1,
    //     pageSize: 1,
    //   };
    //   let ret = await this.$Api(getPresaleList, req);
    //   // console.log(ret, "-=--==-==============");
    //   if (ret && ret.code == 200) {
    //     this.activityDetail = ret.data.activityDetail;
    //     this.handleBg();
    //     // 预售浮窗
    //     if (
    //       this.activityDetail.status == 0 ||
    //       this.activityDetail.status == 1
    //     ) {
    //       this.isShowPersaleFC = "ys";
    //     } else if (
    //       (this.activityDetail.status == 2 ||
    //         this.activityDetail.status == 3) &&
    //       judgeTime(this.activityDetail.startTime)
    //     ) {
    //       //支付尾款浮窗
    //       this.isShowPersaleFC = "pay";
    //     } else if (
    //       (this.activityDetail.status == 2 ||
    //         this.activityDetail.status == 3) &&
    //       !judgeTime(this.activityDetail.startTime)
    //     ) {
    //       //预售
    //       this.isShowPersaleFC = "ys";
    //     } else {
    //       this.isShowPersaleFC = "";
    //     }
    //   }
    // },
  },
};

// const props = defineProps<{
//   isOpen: boolean;
// }>();

// const isShow = computed(() => {
//   return props.isOpen;
// });

// const emit = defineEmits(['close']);
</script>
<style lang="scss" scoped>
/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}
.persalePopup {
  height: 100%;
  width: 100%;
  position: relative;
  .wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);
    width: 337px !important;
    height: 428px !important;
    :deep(img) {
      width: 337px;
      height: 428px;
    }
    // box-sizing: border-box;
    // background: url("../../assets/png/presale/PayPresalePopupBg.png");
    // background-size: 100% 100%;
    .content {
      padding: 27px 0;
      box-sizing: border-box;
      text-align: center;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 1px;
    }
    .messBox {
      width: 244px;
      height: 80px;
      margin: 0 auto 36px;
      background: rgb(64, 64, 64);
      border-radius: 10px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 16px;
      letter-spacing: 1px;
      p {
        margin-bottom: 8px;
      }
    }
    .btn {
      width: 242px;
      height: 50px;
      margin: 333px auto 0;
      border-radius: 25px;
      background: linear-gradient(180deg, #fdf0d0 0%, #fdd887 100%),
        linear-gradient(173deg, #fceee1 -3.81%, #fecd9e 68.1%, #fceee1 146.45%);
      color: #c62e19;
      font-weight: 600;
      font-size: 16px;
    }
  }
}
</style>
